<template>
    <v-data-table
      :headers="headers"
      :items="items"
      :server-items-length="total"
      :options.sync="options"
      :loading="loading"
      class="elevation-1"
      item-key="id"
    >

      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title>Días No Laborables</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>

          <v-spacer></v-spacer>

          <v-menu
            v-model="menuDesde"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="filter_desde"
                type="date"
                label="Desde"
                prepend-icon="mdi-calendar"
                single-line
                hide-details
                clearable
                readonly
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="filter_desde" @input="menuDesde = false"></v-date-picker>
          </v-menu>

          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>

          <v-btn color="primary" dark class="mx-4" @click="importarFeriados" :loading="importarLoading" >Importar 2024</v-btn>

          <v-dialog v-model="dialog" persistent max-width="500px">
            <template v-slot:activator="{ on }">
              <v-btn color="primary" dark v-on="on">Nuevo</v-btn>
            </template>
            <ValidationObserver ref="obs" v-slot="{ invalid, validated, passes }">
              <v-form @submit.prevent="passes(login)">
                <v-card>
                  <v-card-title>
                    <span class="headline">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-menu
                        ref="datepickerFecha"
                        v-model="datepickerFecha"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                      >
                        <template v-slot:activator="{ /*on*/ }">
                          <ValidationProvider vid="fecha" name="Fecha" rules="required" v-slot="{ errors, valid }">
                            <v-text-field
                              type="date"
                              ref="fecha"
                              v-model="item.fecha"
                              filled
                              dense
                              label="Fecha"
                              prepend-icon="mdi-calendar"
                              :error-messages="errors"
                              :success="valid"
                              v-on:click:prepend="openDatepickerFecha"
                              v-on:keydown.enter.stop.prevent="openDatepickerFecha"
                            ></v-text-field>
                          </ValidationProvider>
                        </template>
                        <v-date-picker
                          v-model="item.fecha"
                          scrollable
                          @change="closeDatepickerFecha"
                        ></v-date-picker>
                      </v-menu>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text :disabled="saving" @click="close">Cancelar</v-btn>
                    <v-btn type="submit" @click.prevent="passes(save)" color="primary"
                        dark :loading="saving">Guardar</v-btn>
                  </v-card-actions>
                </v-card>
              </v-form>
            </ValidationObserver>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.fecha="{ item }" class="text-center">
        {{ formatDate(item.fecha) }} 
      </template>
      <template v-slot:item.action="{ item }">
        <v-icon
          small
          class="mr-2"
          @click="editItem(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          small
          @click="deleteItem(item)"
        >
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
</template>

<script>

import {
  ValidationObserver,
  ValidationProvider
} from "vee-validate"
import _ from 'lodash'

export default {
  components: {
    ValidationProvider,
    ValidationObserver
  },

  data: () => ({
    dialog: false,
    loading: false,
    saving: false,
    importarLoading: false,
    headers: [
      { text: 'Fecha', value: 'fecha' },
      { text: 'Advertencias', value: 'advertencias' },
      { text: 'Acciones', value: 'action', align: 'right', sortable: false },
    ],
    items: [],
    total: 0,
    options: {
      sortBy: ['fecha'],
      sortDesc: [false],
    },
    menuDesde:false,
    filter_desde: new Date().toISOString().substr(0, 10),
    item: {
      fecha: null,
    },
    defaultItem: {
      fecha: null,
    },
    datepickerFecha: false,
  }),

  computed: {
    formTitle () {
      return (typeof this.item.id === 'undefined') ? 'Nuevo' : 'Editar'
    },
  },

  watch: {
    dialog (val) {
      val || this.close()
    },

    options: {
      handler () {
        this.query()
      },
      deep: true,
    },

    filter_desde: function (/*val*/) {
      this.debounceRefresh()
    },
  },

  methods: {
    debounceRefresh: _.debounce(function () {
      this.refresh()
    }, 1000),

    formatDate (date) {
      if (!date) return null

      const [year, month, day] = date.split('-')

      return `${day}/${month}/${year}`
    },

    refresh: function () {
      this.options.page = 1
      this.query()
    },

    query: function () {
      this.loading = true;

      let filters = {
        desde: this.filter_desde,
        sortBy: this.options.sortBy[0] || null,
        descending: this.options.sortDesc[0] || null,
        rowsPerPage: this.options.itemsPerPage,
        page: this.options.page,
      };

      this.$http.get('dias-no-laborables', {params: filters})
      .then((response) => {
        this.items = response.data
        this.total = parseInt(response.headers['pager-total'])
      })
      .catch((error) => {
        switch (error.response.status) {
          case 401:
            break;
          default:
            this.$eventHub.$emit('snackbar-message', 'Disculpe, ocurrío un error al procesar su solicitud', 'error')
        }
      })
      .then(() => {
        this.loading = false;
      })
    },

    save: function () {
      this.saving = true

      if (typeof this.item.id === 'undefined') {
        this.create()
      } else {
        this.update()
      }
    },

    create: function () {
      this.$http.post('dias-no-laborables', this.item)
        .then((response) => {
          let message = 'El registro fue creado!'
          let color = 'success'

          this.query()
          this.close()

          if (response.data.warning) {
            message = 'El registro fue creado. ' + response.data.warning
            color = 'warning'
          }

          this.$eventHub.$emit('snackbar-message', message, color)
        })
        .catch((error) => {
          switch (error.response.status) {
            case 401:
              break;
            case 422:
              this.$eventHub.$emit('snackbar-message', 'Hay errores en el formulario')

              this.$refs.obs.setErrors(error.response.data.errors);

              break;
            default:
              this.$eventHub.$emit('snackbar-message', 'Disculpe, ocurrío un error al procesar su solicitud')
          }
        })
        .finally(() => {
          this.saving = false
        })
    },

    update: function () {
        this.$http.put('dias-no-laborables/'+this.item.id, this.item)
        .then((response) => {
          let message = 'El registro fue actualizado!'
          let color = 'success'

          this.query()
          this.close()

          if (response.data.warning) {
            message = 'El registro fue actualizado. ' + response.data.warning
            color = 'warning'
          }

          this.$eventHub.$emit('snackbar-message', message, color)
        })
        .catch((error) => {
          switch (error.response.status) {
            case 422:
              this.$eventHub.$emit('snackbar-message', 'Hay errores en el formulario')

              this.$refs.obs.setErrors(error.response.data.errors);

              break;
            default:
              this.$eventHub.$emit('snackbar-message', 'Disculpe, ocurrío un error al procesar su solicitud')
          }
        })
        .finally(() => {
          this.saving = false
        })
    },

    editItem: function (item) {
      this.$http.get('dias-no-laborables/'+item.id)
        .then((response) => {
          this.item = response.data
          this.dialog = true
        })
        .catch((error) => {
          alert(error);
        })
    },

    deleteItem: function (item) {
      if ( confirm('Está usted seguro que quiere eliminar este registro?') ) {
        this.$http.delete('dias-no-laborables/'+item.id)
          .then(() => {
            this.query();
          })
          .catch((error) => {
            switch (error.response.status) {
              case 422:
                this.$eventHub.$emit('snackbar-message', 'Hay errores en el formulario', 'error')
                break;
              default:
                this.$eventHub.$emit('snackbar-message', 'Disculpe, ocurrío un error al procesar su solicitud', 'error')
            }
          })
      }
    },

    resetForm: function () {
      this.$refs.obs.reset()
      this.item = {...this.defaultItem}
    },

    close: function () {
      this.resetForm()
      this.dialog = false
    },

    openDatepickerFecha: function () {
      this.datepickerFecha = true
    },

    closeDatepickerFecha: function () {
      this.datepickerFecha = false

      setTimeout(() => {
        this.$refs.fecha.$el.querySelector('input').focus()
      }, 10);
    },

    importarFeriados: function () {
      this.importarLoading = true

      this.$http.post('dias-no-laborables/importar-feriados')
        .then((response) => {
          this.query()

          this.$eventHub.$emit('snackbar-message', response.data.message, 'success')
        })
        .catch((error) => {
          switch (error.response.status) {
            case 401:
              break;
            default:
              this.$eventHub.$emit('snackbar-message', 'Disculpe, ocurrío un error al procesar su solicitud')
          }
        })
        .finally(() => {
          this.importarLoading = false
        })
    }
  },
};
</script>
